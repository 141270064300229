import SEO from "components/seo"
import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

const ContentContainer = styled.div`
align-self: flex-end;
  margin: 5rem 0;
  

  h2 {
    background: rgba(11, 11, 11, 0.99);
    background: url("/dark_paper.png");
    margin-bottom: 1rem;
    position: relative;
  }
`

const Content = styled.div`
  background: rgba(230, 230, 230, 0.99);
  background: url("/white_paper.png");
  padding: 2rem 2rem;
  color: black;
  position: relative;
  
  border: 1px solid rgba(10, 10, 10, 0.98);
box-shadow: 3px 3px 0px 0px rgba(30,30,30, 0.4);
  p {
    margin: 0 0 1rem;
  }
  
  p:last-child {
    margin-bottom: 0;
  }
  
 
}
`

export const Card = ({className, title, children}) => {
 return (
    <ContentContainer className={className}>
      <SEO title={title} />
      <h2>{title}</h2>
      <Content>
        {children}
      </Content>
    </ContentContainer>
   )
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
}

Card.defaultProps = {
  className: ''
}
