import { WEARE } from "canvas/SceneEnum"
import { Card } from "components/Card"
import React, { useEffect, useState } from "react"
import styled from 'styled-components'

import Layout from '../components/layout'

import { TimelineDispatchContext, TimelineStateContext } from "components/Backdrop"

const Container = styled.div`
  width: 20vw;
  align-self: flex-end;
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  
  transition: opacity 1s;
  opacity: ${props => props.animateContent ? "23vw" : "0"};
`
const PAGE = WEARE
const WeArePage = () => {
  const startScene = React.useContext(TimelineDispatchContext)

  const timeline = React.useContext(TimelineStateContext)
  const animateContent = typeof timeline === 'object' && timeline.currentScene === PAGE && !timeline.transitioning

  useEffect(() => {
    startScene(PAGE)
  }, []) // Make sure the effect runs only once

  return (
    <Layout>
      <Container animateContent={animateContent}>
        <Card title='Community'>
          <p>
            We are committed to improving ourselves that is why we <strong>value honest
            feedback</strong> and hold each other accountable.
          </p>
          <p>
            Also essential is being <strong>supportive, open minded</strong> and above all <strong>forgiving</strong> when somebody stumbles
            along the way of improving themselves. Together we can turn failure into a teaching moment.
          </p>
          <p>
            <strong>Good or bad days</strong>, you’re never alone when  <strong>you are in great company.</strong>
          </p>
        </Card>
        <Card title='Pride and joy'>
          <p>
            We take pride in what we do, <strong>want to be better</strong> at it and obtain joy
            from the process. Sushi chefs spend countless of years <strong>perfecting
            their craft</strong> to the smallest detail in search of Shokunin.
          </p>
          <p>
            <strong>Commitment to excellence everyday</strong> with passion is what drives us <strong>to
            deliver tailor made solutions.</strong>
          </p>
        </Card>
        <Card title='Team'>
          <p>
            Working as a team empowers us to accomplish great results and
            overcome problems we couldn’t tackle individually. It’s in our
            differences that lies our strength, to view each challenge from
            multiple perspectives and realise the potential of each opportunity.
          </p>
          <p>
            From early insights and strategy to design and development, we
            collaborate with our clients to deliver valuable engagement with
            their users.
          </p>
        </Card>
        <Card title="Interwoven">
          <p>
            We see ourselves as an extension of your team rather than an outside
            partner. Working together is a fantastical adventure where we meet,
            communicate, set expectations, learn to trust each other, and work
            together.
          </p>
          <p>
            Our process is transparent and iterative, so you’ll always know when
            and why we are researching, brainstorming, concepting, sketching,
            designing and programming.
          </p>
        </Card>
        <Card title="Curiosity">
          <p>
            After a hard days work we like to reminiscence about the good old
            days and look beyond to the challenges that lie ahead. Like kids on
            christmas morning we flock together whenever a new piece of
            technology arrives, eagerly waiting till we can play with it,
            analysing it or even take it apart. Always a recipe for a great
            night.
          </p>
          <p>
            Some of the best ideas are made while having fun, curious isn’t it?
          </p>
        </Card>
      </Container>
    </Layout>
  )
}

export default WeArePage
